body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

.react-toast-notifications__container{
  z-index: 10000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.custom-table-header {
  background: #FFFFFF;
  padding: 8px 15px;
  box-shadow: 0px 1px 2px #00000055;
}

.custom-table-header div {
  font-weight: 700;
}

.custom-table-row {
  padding: 5px 8px;
}

.orderRow td {
  border: 1px solid #e4e4e4;
  border-radius: 0px;
  margin-bottom: 2px;
}
  
.invoiceRow td{
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  margin-bottom: 2px;
}

.tableRowCount {
    margin-bottom: 5px;
    font-size: 10px;
    font-weight: 600;
    color: #8c8c8c;
    text-align: right;
}

.ant-table-placeholder {
    border-top: 0px !important;
}

.customerInfoCard .ant-card-body{
  padding: 15px
}

.collectionRow td{
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  margin-bottom: 2px;
}

.internet-error {
  height: 40px;
  background: #ff8100;
  margin-top: 0;
  font-size: 14px;
  text-align: center;
}

.root-prompt {
  background: #ff8100;
  margin-top: 0;
  font-size: 14px;
  color: #ffffff;
  text-align: center;;
}

.root-prompt .button-group {
  display: inline;
  margin: 10;
}

.internet-error p {
  font-size: 14px;
  line-height: 40px;
  color: #fff;
  margin: 0;
}

.internet-lost,
.offline-mode {
  height: 20px;
}

.server-connection-lost{
  height: 45px;
}

.server-connection-lost,
.internet-lost,
.offline-mode {
  background: #ff8100;
  margin-top: 0;
  font-size: 14px;
  text-align: center;
  width: 100%;
  top: 0;
  z-index: 100;
}

.server-connection-lost p,
.internet-lost p, 
.offline-mode p {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin: 0;
}

.callBtn .ant-affix {
  z-index: 2000;
}