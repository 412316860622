.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Scrollbar */
/* Width */
.thin-scrollbar::-webkit-scrollbar {
  width: 1px;
  height: 0px;
}

/* Track */
.thin-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #bbb;
}

/* Handle on hover */
.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #777;
}

/* !Scrollbar */

.css-1py7kdp-ToastContainer {
  z-index: 10000000;
}

.css-1ciik4v {
  z-index: 10000000 !important;
}

.promate-form-header {
  margin: 5px;
}

.promate-form-divider {
  margin-top: 0px;
  margin-left: 5px;
}



.promate-form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-right: 10px;
}

.ant-table-footer {
  padding: 0px !important;
}

.ant-table-footer .item-list-view{
  margin-left: -10px;
}

.ant-table-thead{
  background-color: rgb(236, 234, 234);
}

.ant-table-small{
  border : none !important;
}

.ant-table-title {
  padding-bottom: 0 !important;
  border-bottom: none !important;
}

.ant-table .ant-table-content {
  padding: 0 8px;
}

.ant-table .ant-table-content .ant-table-body {
  margin-left: 0;
  margin-right: 0;
}

.ant-table-header {
  margin-right: -15px;
}

.list-view-action-buttons {
  background-color: #eda01e; 
  color: #FFFFFF; 
  z-index: 10000; 
  position: absolute;
  top: 30px;
  right: 105px;
}

.sales-header-selected-item-row {
  background-color: #e5fcd8;
}

.promate-table-pagination {
  position: fixed;
  bottom: -3px;
  right: 10px;
}

#route-name:hover {
  outline: none !important;
  box-shadow: none !important;
  border-color: #d9d9d9;
}

#route-name:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #d9d9d9;
}

.ant-calendar-picker-clear, .ant-calendar-picker-icon{
  z-index: 0 !important;;
}

.ant-select-disabled {
  color: rgb(0 0 0 / 75%) !important;
}

.ant-input[disabled] {
  color: rgb(0 0 0 / 75%) !important;
}

.ant-table-body{
  overflow-y: overlay!important;
}

.arrowless::-webkit-inner-spin-button, .arrowless::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.arrowless {
  -moz-appearance:textfield !important;
}

.hovering-scroller {
  overflow-y: overlay!important;
}

.editable-row {
  padding: 0;
  margin: 0;
  height: 40px!important;
}

.editable-row td{
  padding: 0 !important;
  margin: 0 !important;

}

.editable-row tr th{
  padding: 0 !important;
  margin: 0 !important;
 
}


.ant-table-thead tr .return-qty-column {
  padding: 10px 8px !important;
  border-top-right-radius : 0px !important
}

.ant-table-thead tr .total-column {
  padding: 10px 8px !important;
  border-top-right-radius : 0px !important
}

.ant-table-thead tr .qty-column {
  padding: 10px 8px !important;
  border-top-right-radius : 0px !important
}

.ant-table-thead tr .discount-column {
  padding: 10px 8px !important;
 
}
.ant-table-thead tr .unit-price-column {
  padding: 10px 8px !important;
}

.ant-table-thead tr .item-code-column {
  padding: 10px 8px !important;
}

.ant-table table { font-size: 12px; }

.ant-table-content{
  padding: 0 !important;
}

.table-row-danger {
  background-color: #FFCBCB;
}
.table-row-normal {
  background-color: transparent;
}


